import { IconName } from '@/components/ui/dynamic-icon';

export type BaseUrl = { url: string; label: string };
export type WithDescriptionAndIcon = BaseUrl & { description: string; icon?: IconName; isHidden?: boolean };
export type WithDescriptionAndImage = BaseUrl & { description: string; image: string; isHidden?: boolean };
export type WithIcon = BaseUrl & { icon: IconName; isHidden?: boolean };

// All Pages
export const marketingPages = {
  features: {
    url: '/features',
    label: 'Features',
  },
  featureRoutePlanning: {
    url: '/features/route-planning',
    label: 'Route Planning',
    description: 'Plan across all transport modes',
    icon: 'git-compare',
  },
  featurePortInsights: {
    url: '/features/port-insights',
    label: 'Port Insights',
    description: 'Discover global transport connections',
    icon: 'radar',
  },
  featureTracking: {
    url: '/features/shipment-tracking',
    label: 'Shipment Tracking',
    description: 'Get real-time ETA updates on your shipments',
    icon: 'package-search',
  },
  featureCarriers: {
    url: '/features/carriers',
    label: 'Carrier Directory',
    description: 'Find the right carrier for your shipping needs',
  },
  featureSchedules: {
    url: '/features/schedules',
    label: 'Shipping Schedules',
    description: 'Search and compare all Air and Ocean schedules',
    icon: 'calendar-range',
  },
  featureEmissions: {
    url: '/features/emissions',
    label: 'Emissions',
    description: 'Estimate emissions on shipments',
    icon: 'leaf',
  },
  featureApi: {
    url: '/features/api',
    label: 'API',
    description: 'Enable software communication',
    icon: 'file-code',
  },
  solutionsForwarders: {
    label: 'For Freight Forwarders',
    description: 'Managing the entire shipping process',
    url: '/solutions/forwarders',
    image: '/assets/nav/technician-and-engineer@2x.jpg',
  },
  solutionsCarriers: {
    label: 'For Carriers',
    description: 'Manage the transportation and coordination of goods',
    url: '/solutions/carriers',
    image: '/assets/nav/container-ship@2x.jpg',
  },
  solutionsShippers: {
    label: 'For Shippers',
    description: 'Ensure efficient and timely shipments',
    url: '/solutions/shippers',
    image: '/assets/nav/cargo-on-runway@2x.jpg',
  },
  solutionsConsultants: {
    label: 'For Consultants',
    description: 'Manage supply chain efficiency and improve delivery timelines.',
    url: '/solutions/consultants',
    image: '/assets/nav/consultants@2x.jpg',
  },
  plans: {
    url: '/plans',
    label: 'Plans & Pricing',
  },
  releaseNotes: {
    url: '/release-notes',
    label: 'Release Notes',
  },
  sources: {
    url: '/sources',
    label: 'Data Partners',
  },
  about: {
    url: '/about',
    label: 'About',
    icon: 'building-2',
  },
  contact: {
    url: '/contact',
    label: 'Contact Us',
    icon: 'mail',
  },
  getQuote: {
    url: '/get-a-quote',
    label: 'Shipping enquiry',
  },
  careers: {
    url: '/about#careers',
    label: 'Careers',
    icon: 'briefcase',
  },
  privacy: {
    url: '/privacy',
    label: 'Privacy Policy',
  },
  terms: {
    url: '/terms-of-use',
    label: 'Terms of Use',
  },
  ports: {
    url: '/ports',
    label: 'Seaports & Airports',
  },
  carriers: {
    url: '/carriers',
    label: 'Carrier Directory',
  },
  blog: {
    url: '/blog',
    label: 'Blog',
    description: 'Latest industry news and company updates from the team at Fluent Cargo',
    icon: 'square-pen',
  },
  resources: {
    label: 'Resources',
    url: '/resources', // Doesn't actually exist
  },
  solutions: {
    label: 'Solutions',
    url: '/solutions', // Doesn't actually exist
  },
  glossary: {
    url: '/glossary',
    label: 'Industry Glossary',
  },
  press: {
    url: '/press',
    label: 'Press & News',
    description: 'Discover relevant shipping and logistics news, industry updates and company updates',
    icon: 'newspaper',
  },
  caseStudies: {
    url: '/case-studies',
    label: 'Case Studies',
    description: 'See how our customers optimize logistics and enhance shipping operations with Fluent Cargo',
    icon: 'book-open-text',
  },
  faqs: {
    url: '/faqs',
    label: 'FAQs',
    icon: 'life-buoy',
  },
  youtube: {
    url: 'https://www.youtube.com/channel/UCr5p5jqtuO5S39vdURqjPLg',
    label: 'YouTube',
    icon: 'youtube',
  },
  linkedin: {
    url: 'https://au.linkedin.com/company/fluentcargo',
    label: 'LinkedIn',
    icon: 'linkedin',
  },
} as const;

export interface FooterNav {
  product: {
    heading: string;
    items: BaseUrl[];
  };
  features: {
    heading: string;
    items: WithDescriptionAndIcon[];
  };
  resources: {
    heading: string;
    items: BaseUrl[];
  };
  solutions: {
    heading: string;
    items: WithDescriptionAndImage[];
  };
}

// Footer Nav Items
export const footerNav: FooterNav = {
  product: {
    heading: 'Product',
    items: [
      marketingPages.features,
      marketingPages.plans,
      marketingPages.sources,
      marketingPages.ports,
      marketingPages.carriers,
    ],
  },
  features: {
    heading: 'Features',
    items: [
      marketingPages.featureRoutePlanning,
      marketingPages.featureTracking,
      marketingPages.featureSchedules,
      marketingPages.featureEmissions,
      marketingPages.featurePortInsights,
      marketingPages.featureApi,
    ],
  },
  solutions: {
    heading: 'Solutions',
    items: [
      marketingPages.solutionsShippers,
      marketingPages.solutionsForwarders,
      marketingPages.solutionsCarriers,
      marketingPages.solutionsConsultants,
    ],
  },
  resources: {
    heading: 'Resources',
    items: [
      marketingPages.about,
      marketingPages.blog,
      marketingPages.faqs,
      marketingPages.caseStudies,
      marketingPages.press,
      marketingPages.contact,
    ],
  },
};

export type FooterNavSocial = {
  items: WithIcon[];
};

export const footerNavSocial: FooterNavSocial = {
  items: [marketingPages.linkedin],
};

type PostType = 'productPosts' | 'newsPosts';

export interface HeaderNavItems {
  url: string;
  label: string;
  links?: WithDescriptionAndIcon[];
  linksMini?: { heading: string; links: WithIcon[] }[];
  className?: string;
  icon?: string;
  subSectionHeader?: string;
  posts?: PostType;
  cards?: {
    heading?: string;
    isHidden?: boolean;
    items: {
      label: string;
      description: string;
      url: string;
      image: string;
    }[];
  }[];
  isHidden?: boolean;
}

// Primary Nav Items
export const headerNav: HeaderNavItems[] = [
  {
    ...marketingPages.features,
    links: [
      marketingPages.featureRoutePlanning,
      marketingPages.featureSchedules,
      marketingPages.featureTracking,
      marketingPages.featurePortInsights,
      marketingPages.featureEmissions,
      marketingPages.featureApi,
    ],
    subSectionHeader: 'Product updates',
    posts: 'productPosts',
  },
  {
    ...marketingPages.solutions,
    cards: [
      {
        items: [
          {
            label: 'Why Fluent Cargo?',
            description: 'Plan and manage your shipments better',
            url: '/benefits',
            image: '/assets/nav/containers-in-yard@2x.jpg',
          },
        ],
        isHidden: true,
      },
      {
        heading: 'Logistics solutions',
        items: [
          marketingPages.solutionsShippers,
          marketingPages.solutionsForwarders,
          marketingPages.solutionsCarriers,
          marketingPages.solutionsConsultants,
        ],
      },
    ],
  },
  {
    ...marketingPages.resources,
    linksMini: [
      { heading: 'News', links: [marketingPages.blog, marketingPages.press, marketingPages.caseStudies] },
      {
        heading: 'Company',
        links: [marketingPages.about, marketingPages.careers, marketingPages.contact, marketingPages.faqs],
      },
    ],
    subSectionHeader: 'Latest news',
    posts: 'newsPosts',
    className: 'w-[520px] md:grid-cols-[200px_1fr]',
  },
  { ...marketingPages.plans, links: [] },
];
